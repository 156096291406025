import React, { useState, useEffect } from "react";
import { db } from "../../Firebase";
import { useNavigate } from "react-router-dom";
import { collection, doc, getDocs } from "firebase/firestore";
import CircularProgress from "@mui/material/CircularProgress";
import {
  PricingCardContainer,
  CardFilterButton,
  storeBadgeStyle,
  Row,
  PricingCard,
  RedBackground,
  WhiteBackground,
  RedContent,
  WhiteContent,
  CardTextContainer,
  CardSubtext,
  CardParagraph,
  CardHeading,
} from "./PricingCardsStyles";
import ImageWithFixedHeight from "../ImageWithFixedHeight";

function PricingCards() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    firstRow: [],
    secondRow: [],
    thirdRow: [],
  });

  const navigate = useNavigate();

  const [activeSection, setActiveSection] = useState("AdultAndKidsPricing");

  const landingConfigDocRef = doc(
    db,
    "LandingConfiguration",
    "x3aWKMi87yl6nMO0RtLh"
  );

  const handleCardClick = (card) => {
//    navigate(`/pricing-details/${card.collectionName}/${card.id}`);
    navigate(`/under-construction`);
  };

  const fetchDataForCollection = async (collectionName) => {
    const rowCollectionRef = collection(landingConfigDocRef, collectionName);
    const rowData = await getDocs(rowCollectionRef);

    let cardsData = rowData.docs.map((doc) => ({
      id: doc.id,
      collectionName: collectionName, // Agrega el nombre de la colección
      ...doc.data(),
    }));

    // Ordenamos las tarjetas basadas en el campo 'order'
    cardsData.sort((a, b) => a.order - b.order);

    cardsData = cardsData.map((data) => {
      switch (collectionName) {
        case "AdultAndKidsPricing":
          return {
            ...data,
            typePrice: data.kidsPrice,
            typeDescription: data.kidsDescription,
          };
        case "FamilyMembershipPricing":
          return {
            ...data,
            typePrice: data.membersPrice,
            typeDescription: data.membersDescription,
          };
        case "FullMembershipsPricing":
          return {
            ...data,
            // Aquí puedes agregar cualquier lógica adicional específica para esta colección
          };
        default:
          return data;
      }
    });

    return cardsData;
  };

  const fetchData = async () => {
    setLoading(true);

    try {
      const firstRow = await fetchDataForCollection("AdultAndKidsPricing");
      const secondRow = await fetchDataForCollection("FamilyMembershipPricing");
      const thirdRow = await fetchDataForCollection("FullMembershipsPricing");

      setData({ firstRow, secondRow, thirdRow });
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <div
        style={{ display: "flex", justifyContent: "center", margin: "20px 0" }}
      >
        <CardFilterButton
          active={activeSection === "AdultAndKidsPricing"}
          onClick={() => setActiveSection("AdultAndKidsPricing")}
//            onClick= {() => handleCardClick(card)}
        >
          Adult & Kids
        </CardFilterButton>
        <CardFilterButton
          active={activeSection === "FamilyMembershipPricing"}
          onClick={() => setActiveSection("FamilyMembershipPricing")}
//            onClick= {() => handleCardClick(card)}
        >
          Family Memberships
        </CardFilterButton>
      </div>

      <PricingCardContainer>
        {/* Adult & Kids */}
        {activeSection === "AdultAndKidsPricing" && (
          <div>
            <div>
              <CardSubtext>Adult & Kids</CardSubtext>
            </div>

            <Row>
              {data.firstRow.map((card, index) => (
                <PricingCard
                  onClick={() => handleCardClick(card)} // Pasa el objeto 'card' completo
                  key={index}
                  highlighted={card.isHighlighted}
                >
                  <RedBackground>
                    <CardTextContainer as={RedContent}>
                      <CardParagraph>
                        <strong>{card.specialDescription}</strong>
                      </CardParagraph>
                      <CardParagraph>{card.duration}</CardParagraph>
                      <CardHeading>{card.price}</CardHeading>
                      <CardParagraph>{card.description}</CardParagraph>
                    </CardTextContainer>
                  </RedBackground>
                  <WhiteBackground>
                    <CardTextContainer as={WhiteContent}>
                      <CardParagraph>{card.type}</CardParagraph>
                      <CardHeading>{card.typePrice}</CardHeading>
                      <CardParagraph>{card.typeDescription}</CardParagraph>
                    </CardTextContainer>
                  </WhiteBackground>
                </PricingCard>
              ))}
            </Row>
          </div>
        )}

        {activeSection === "FamilyMembershipPricing" && (
          <div>
            <div>
              <CardSubtext>Family Memberships</CardSubtext>
              <CardSubtext>Families of 3</CardSubtext>
            </div>
            <Row>
              {data.secondRow.map((card, index) => (
                <PricingCard
                  onClick={() => handleCardClick(card)} // Pasa el objeto 'card' completo
                  key={index}
                  highlighted={card.isHighlighted}
                >
                  <WhiteBackground>
                    <CardTextContainer as={RedContent}>
                      <CardParagraph>
                        <strong>{card.specialDescription}</strong>
                      </CardParagraph>
                      <CardParagraph>{card.duration}</CardParagraph>
                      <CardHeading>{card.price}</CardHeading>
                      <CardParagraph>{card.description}</CardParagraph>
                    </CardTextContainer>
                  </WhiteBackground>
                  <RedBackground>
                    <CardTextContainer as={WhiteContent}>
                      <CardParagraph>{card.type}</CardParagraph>
                      <CardHeading>{card.typePrice}</CardHeading>
                      <CardParagraph>{card.typeDescription}</CardParagraph>
                    </CardTextContainer>
                  </RedBackground>
                </PricingCard>
              ))}
            </Row>
          </div>
        )}

        <div>
          <CardSubtext>Paid In Full Memberships</CardSubtext>
        </div>
        <Row>
          {data.thirdRow.map((card, index) => (
            <PricingCard
              onClick={() => handleCardClick(card)} // Pasa el objeto 'card' completo
              key={index}
              highlighted={card.isHighlighted}
            >
              <RedBackground>
                <CardTextContainer as={RedContent}>
                  <CardParagraph>
                    <strong>{card.specialDescription}</strong>
                  </CardParagraph>
                  <CardParagraph>{card.duration}</CardParagraph>
                  <CardHeading>{card.price}</CardHeading>
                  <CardParagraph>{card.description}</CardParagraph>
                </CardTextContainer>
              </RedBackground>
            </PricingCard>
          ))}
        </Row>

        <div>
          <CardSubtext>
            Daily Pass $15 For Visitors & Out Of Town Guests
          </CardSubtext>
        </div>
      </PricingCardContainer>

      <div
        style={{
          textAlign: "left",
          marginTop: "20px",
          marginBottom: "20px",
          marginLeft: "20px",
        }}
      >
        <a href="https://play.google.com/store/apps/details?id=com.codingsolved.nsbjjapp&pcampaignid=web_share" target="_blank" rel="noopener noreferrer" style={{ marginRight: "10px" }}>
            <ImageWithFixedHeight
                      src="/Google_Play_Store_badge_EN.png"
                      alt="Available in Google Play"
                      fixedHeight={70}
            />
        </a>
        <a href="https://apps.apple.com/us/app/north-side-bjj/id6476119325" target="_blank" rel="noopener noreferrer">
            <ImageWithFixedHeight
                      src="/app_store_badge.png"
                      alt="Available in the App Store"
                      fixedHeight={70}
            />
        </a>
      </div>
    </div>
  );
}

export default PricingCards;
