import React, { useEffect, useState } from 'react';
import CircularProgress from "@mui/material/CircularProgress";
import { db } from "../../Firebase"; 
import { collection, getDocs, doc } from "firebase/firestore";
import { GalleryContainer, GalleryRow, GalleryItem, ImageBox } from './GalleryStyles';

function chunkArray(array, chunkSize) {
  const results = [];
  while (array.length) {
    results.push(array.splice(0, chunkSize));
  }
  return results;
}

function Gallery({ collectionName, excludeCount = 0 }) {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Agrega un event listener para el redimensionamiento de la ventana
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const fetchGalleryImages = async () => {
      const landingConfigDocRef = doc(db, "LandingConfiguration", "x3aWKMi87yl6nMO0RtLh");
      const galleryCollectionRef = collection(landingConfigDocRef, collectionName);

      const gallerySnapshot = await getDocs(galleryCollectionRef);
      const galleryDataList = gallerySnapshot.docs.map((doc) => doc.data())
      

      // Ordena el array basado en el campo "order" si lo tiene
      const sortedGalleryList = galleryDataList.sort((a, b) => a.order - b.order).map((data) => data.image);
      
      setImages(sortedGalleryList);
      setLoading(false);
    };

    fetchGalleryImages();
  }, [collectionName]);

  const chunkSize = windowWidth < 768 ? 2 : 3; // Si es móvil, chunks de 2, de lo contrario, chunks de 3
  const imageChunks = chunkArray([...images].slice(excludeCount), chunkSize);

  return (
    <GalleryContainer>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          {imageChunks.map((imageChunk, chunkIndex) => (
            <GalleryRow key={chunkIndex}>
              {imageChunk.map((image, index) => (
                <GalleryItem key={index + chunkIndex * 3}>
                  <ImageBox src={image} alt={`Gallery Image ${index + 1 + chunkIndex * 3}`} />
                </GalleryItem>
              ))}
            </GalleryRow>
          ))}
        </>
      )}
    </GalleryContainer>
  );
}

export default Gallery;
