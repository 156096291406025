import React, { useState, useEffect } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-calendar/dist/Calendar.css";
import {
  StyledCalendar,
  StyledOption,
  StyledSelect,
  DropdownContainer,
  DropdownContent,
  CalendarContainer,
  FullContainer,
  Title,
  
} from "./CalendarStyles";
import WeekEvents from "./WeekEvents";
import { startOfWeek, endOfWeek, eachDayOfInterval, format } from "date-fns";
import { db } from "../../Firebase"; // Importar la instancia de Firestore
import { doc, collection, getDocs, where, query } from "firebase/firestore";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";

const localizer = momentLocalizer(moment);
function CalendarView() {
  const defaultDate = new Date(); // Set your default date here

  const [selectedDatesRange, setSelectedDatesRange] = useState([
    startOfWeek(defaultDate, { weekStartsOn: 0 }),
    endOfWeek(defaultDate, { weekStartsOn: 0 }),
  ]);

  const [selectedDate, setSelectedDate] = useState(defaultDate);
  const [selectedAcademy, setSelectedAcademy] = useState(null);
  const [academies, setAcademies] = useState([]);
  const [events, setEvents] = useState([]);
  const [classes, setClasses] = useState([]);

  useEffect(() => {
    // Obtener datos de academias al cargar el componente
    const fetchData = async () => {
      // academy where debugMode is false
      const academiesCollection = collection(db, "academies");
      const q = query(academiesCollection, where("debugMode", "==", false));

      const academiesSnapshot = await getDocs(q);
      const academiesData = academiesSnapshot.docs.map((doc) => ({
        uid: doc.id,
        name: doc.data().name,
      }));

      setAcademies(academiesData);
      if (academiesData.length === 1) {
        setSelectedAcademy(academiesData[0].name);
      }
    };
  


    fetchData();
    
  }, []);

  useEffect(() => {
    // Obtener eventos al cambiar la academia seleccionada
    getEvents();
    getClasses();
  }, [selectedAcademy]);

  const handleDateClick = (value) => {
    const weekStart = startOfWeek(value, { weekStartsOn: 0 });
    const weekEnd = endOfWeek(value, { weekStartsOn: 0 });

    setSelectedDatesRange([weekStart, weekEnd]);
  };

  const handleAcademyChange = (event) => {
    const selectedAcademyName = event.target.value;
    setSelectedAcademy(selectedAcademyName);
  };

  const tileClassName = ({ date, view }) => {
    if (
      view === "month" &&
      date >= selectedDatesRange[0] &&
      date <= selectedDatesRange[1]
    ) {
      return "react-calendar__tile--active";
    }
  };

  const generateWeekDates = () => {
    const weekStart = selectedDatesRange[0];
    const weekEnd = selectedDatesRange[1];

    if (weekStart && weekEnd) {
      const weekDates = eachDayOfInterval({ start: weekStart, end: weekEnd });
      return weekDates;
    }

    return [];
  };

  const getAcademyUIDByName = (academyName) => {
    const selectedAcademy = academies.find(
      (academy) => academy.name === academyName
    );
    return selectedAcademy ? selectedAcademy.uid : null;
  };

  const getEvents = async () => {
    const academyUID = getAcademyUIDByName(selectedAcademy);
    if (academyUID) {
      const eventsCollection = collection(db, "events");
      const q = query(eventsCollection, where("academyUID", "==", academyUID));

      const eventsSnapshot = await getDocs(q);
      const eventsData = eventsSnapshot.docs.map((doc) => ({
        uid: doc.id,
        title: doc.data().title,
        start: doc.data().startTime.toDate(),
        end: doc.data().endTime.toDate(),
        instructorId: doc.data().instructorId,
      }));
      setEvents(eventsData);
  
    }
  };

  const getClasses = async () => {
    const classesRef = collection(db, "/academies/x3aWKMi87yl6nMO0RtLh/classes");

    try {
      const querySnapshot = await getDocs(classesRef);
      const fetchedClasses = querySnapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .filter(cls => cls.isVisible !== false); // Exclude classes where isVisible is explicitly set to false

      const weekClasses = transformClassesToWeekData(fetchedClasses);
      
      setClasses(createClassesList(weekClasses)); // Set the transformed data to state
      //setLoading(false); // Set loading to false after fetching data
    } catch (error) {
      console.error("Error fetching classes:", error);
    }
  };

  const transformClassesToWeekData = (classesData) => {
    const weekData = {
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      sunday: []
    };
  
    classesData.forEach(classData => {
      Object.keys(classData.schedule).forEach(day => {
        if (classData.schedule[day].startTime) { // Assuming a class is scheduled if startTime is set
          weekData[day].push({
            ...classData,
            schedule: classData.schedule[day]
          });
        }
      });
    });
  
    return weekData;
  };

  const getDateOfWeek = (dayName, referenceDate = new Date()) => {
    const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    const dayIndex = daysOfWeek.indexOf(dayName.toLowerCase());
    const currentDayIndex = referenceDate.getDay(); // 0 (Sunday) to 6 (Saturday)
    const difference = dayIndex - currentDayIndex;
    referenceDate.setDate(referenceDate.getDate() + difference);
    return referenceDate;
  };

  const getDateForDayOfWeek = (dayName) => {
    const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    const today = new Date();
    const todayDayIndex = today.getDay();
    const dayIndex = days.indexOf(dayName);

  
    // Calculate the date difference
    const dateDifference = dayIndex - todayDayIndex;
    today.setDate(today.getDate() + dateDifference);
    
    return today;
  };

  function createClassesList(eventsData) {
    let classesList = [];
  
    Object.keys(eventsData).forEach(dayOfWeek => {
      const parsedDate = getDateOfWeek(dayOfWeek); // Ensure this function is defined
  
      eventsData[dayOfWeek].forEach(event => {
        // Assuming event.schedule.startTime and event.schedule.endTime are in a format that can be directly converted to a Date object
        const startTime = event.schedule.startTime ? new Date(parseTime(event.schedule.startTime, parsedDate)) : null;
        const endTime = event.schedule.endTime ? new Date(parseTime(event.schedule.endTime, parsedDate)) : null;
  
        classesList.push({
          uid: event.uid,
          start: startTime,
          end: endTime,
          title: event.className,
          instructorId: event.instructors[0],
        });
      });
    });
  
    return classesList;
  };
  
  

  const timestampToTime = (date) => {
    return format(date, "hh:mm a");
  };

  const parseTime = (timeString, referenceDate) => {
    if (!timeString) {
      return null; // or return a default value if needed
    }
  
    // Extract hours, minutes, and optional modifier (am/pm)
    let [time, modifier] = timeString.split(' ');
    if (!modifier) {
      // Check if the modifier is attached to the time (e.g., "6:00pm")
      const match = time.match(/(\d+:\d+)(am|pm)?/i);
      if (match) {
        time = match[1];
        modifier = match[2];
      }
    }
  
    let [hours, minutes] = time.split(':');
    hours = parseInt(hours, 10);
    minutes = parseInt(minutes, 10);
  
    // Adjust hours based on the modifier
    if (modifier) {
      if (modifier.toLowerCase() === 'pm' && hours < 12) {
        hours += 12;
      }
      if (modifier.toLowerCase() === 'am' && hours === 12) {
        hours = 0;
      }
    }
  
    const date = new Date(referenceDate);
    date.setHours(hours, minutes);
    return date;
  };
  

  const minTime = new Date();
  minTime.setHours(6, 0, 0); // Set the time to 6:00 AM

  const maxTime = new Date();
  maxTime.setHours(22, 0, 0); // Set the time to 10:00 PM

  const handleNavigate = (newDate) => {
    setSelectedDate(newDate);
  };

  return (
    <CalendarContainer>
      <FullContainer>
        <Title>Event Calendar</Title>
        <DropdownContainer>
          <StyledSelect
            id="academyDropdown"
            onChange={handleAcademyChange}
            value={selectedAcademy}
          >
            <StyledOption value={null}>Select an academy</StyledOption>
            {academies.map((academy) => (
              <StyledOption key={academy.uid} value={academy.name}>
                {academy.name}
              </StyledOption>
            ))}
          </StyledSelect>
          <DropdownContent>{/* Contenido del dropdown */}</DropdownContent>
        </DropdownContainer>
        <Calendar
          onNavigate={handleNavigate}
          date={selectedDate}
          localizer={localizer}
          events={events.concat(classes)}
          startAccessor="start"
          defaultView="week"
          views={['week', 'day', 'agenda']}
          onSelectEvent={(event) => alert(event.title)}
          endAccessor="end"
          min={minTime} // Set the minimum time
          max={maxTime} // Set the maximum time
          style={{
            backgroundColor: "black",
            color: "white",
            borderRadius: "5px",
            border: "0",
            height: "100%",
            // maxWidth: "800px",
          }}
        />
        <div style={{ color: "white", marginTop: "10px", textAlign: "center" }}>
      Schedule may change from day to day, so please keep an eye on here.
    </div>
      </FullContainer>
    </CalendarContainer>
  );
}
export default CalendarView;
