import React, { useState, useEffect } from 'react';
import { format, startOfWeek, endOfWeek, eachDayOfInterval, setHours, startOfDay, eachHourOfInterval, isSameDay, parse, getHours } from 'date-fns';
import { DayGrid, EventItem, WeekContainer, WeekGrid, TimeSlotColumn, GridCell, DayColumn, WeekDayHeader , TopSpacer, InstructorLine, ClassItem, ScrollableDayGrid} from './WeekViewStyles';
import {  collection, query, where, getDoc, doc} from 'firebase/firestore';
import { db } from '../../Firebase';

const WeekView = ({ date, events, classes }) => {
  const [activitiesMatrix, setActivitiesMatrix] = useState([]);
  const [weekDays, setWeekDays] = useState([]);
  const [hourlyIntervals, setHourlyIntervals] = useState([]);
  const [instructorNames, setInstructorNames] = useState({});
  const [maxHeightPerSlot, setMaxHeightPerSlot] = useState([]);
  const [isScrolledToStart, setIsScrolledToStart] = useState(true);
  const [isScrolledToEnd, setIsScrolledToEnd] = useState(false);

const handleScroll = (e) => {
  const { scrollLeft, scrollWidth, clientWidth } = e.target;
  const maxScrollLeft = scrollWidth - clientWidth;
  // Determine if we are scrolled to the start (or within a threshold from the start).
    setIsScrolledToStart(scrollLeft < 8); // You can adjust the threshold as needed.
    // Determine if we are scrolled to the end (or within a threshold from the end).
    setIsScrolledToEnd(scrollLeft > maxScrollLeft - 8); // Adjust the threshold as needed.
  };


// For boxShadow, create a string based on the state
const startShadow = isScrolledToStart ? 'inset 35px 0 35px -25px rgba(255,255,255,0.0)' : 'inset 35px 0 35px -25px rgba(255,255,255,0.35)';
const endShadow = isScrolledToEnd ? 'inset 35px 0 35px -25px rgba(255,255,255,0.0)' : 'inset -35px 0 35px -25px rgba(255,255,255,0.35)';
const boxShadow = `${startShadow}, ${endShadow}`;

// Use effect to add or remove the shadow based on scroll position
useEffect(() => {
  const dayGrid = document.querySelector('.DayGrid');
  dayGrid.addEventListener('scroll', handleScroll);

  return () => dayGrid.removeEventListener('scroll', handleScroll);
}, []);

useEffect(() => {
  const fetchInstructorNames = async () => {
    const instructorIds = classes.flatMap(cls =>
      Object.values(cls.schedule)
        .filter(sch => sch.instructorUID)
        .map(sch => sch.instructorUID)
    );

    const uniqueInstructorIds = [...new Set(instructorIds)];
    const namesMapping = {};

    await Promise.all(uniqueInstructorIds.map(async (instructorId) => {
      const docRef = doc(db, "users", instructorId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const { firstname, lastname } = docSnap.data();
        namesMapping[instructorId] = `${firstname} ${lastname}`;
      } else {
        namesMapping[instructorId] = "Unknown Instructor";
      }
    }));

    setInstructorNames(namesMapping);
  };

  fetchInstructorNames();
}, [classes]);


  useEffect(() => {
    const start = startOfWeek(date, { weekStartsOn: 1 });
    const end = endOfWeek(start, { weekStartsOn: 1 });
    const days = eachDayOfInterval({ start, end });
    setWeekDays(days);

    const startTimeRange = setHours(startOfDay(start), 6);
    const endTimeRange = setHours(startOfDay(start), 21);
    const intervals = eachHourOfInterval({ start: startTimeRange, end: endTimeRange });
    setHourlyIntervals(intervals);

    const matrix = intervals.map(() => new Array(7).fill(null).map(() => [])); // Change 'days.length' to '7' if 'days' does not include Sunday

    // Populate the matrix with class and event data
    classes.forEach(cls => {
          days.forEach((day, dayIndex) => {
            const dayOfWeek = format(day, 'EEEE').toLowerCase();
            const schedule = cls.schedule[dayOfWeek];
            if (schedule && schedule.isVisible && schedule.startTime) {
              const startTime = parseTime(schedule.startTime, day);
              const endTime = schedule.endTime ? parseTime(schedule.endTime, day) : null;
              const hourIndex = intervals.findIndex(hour => getHours(hour) === getHours(startTime));
              if (hourIndex !== -1) {
                matrix[hourIndex][dayIndex].push({
                  ...cls,
                  startTime,
                  endTime,
                  type: 'class'
                });
              }
            }
          });
        });

    events.forEach(event => {
      const eventStart = parseTime(event.startTime, start);

      days.forEach((day, dayIndex) => {
        if (isSameDay(eventStart, day) ) {
          const hourIndex = intervals.findIndex(hour =>
            getHours(hour) <= getHours(eventStart) &&
            getHours(eventStart) < getHours(hour) + 1
          );


          if (hourIndex !== -1 && event.onlyShowTo.length === 0) {
            matrix[hourIndex][dayIndex].push({
              ...event,
              startTime: eventStart,
              endTime: event.endTime ? parseTime(event.endTime, start) : null,
              type: 'event'
            });

          } else {
            console.error('Event start time is not within the hourly intervals'); // Indicates a range issue
          }
        }
      });
    });

    setActivitiesMatrix(matrix);

    // Calculate max activities per slot and corresponding heights
    const newMaxHeightPerSlot = hourlyIntervals.map((_, slotIndex) => {
        const activitiesInSlot = activitiesMatrix[slotIndex];
        const maxHeightInSlot = activitiesInSlot.reduce((max, dayActivities) => {
          return Math.max(max, dayActivities.length);
        }, 1); // At least one event's height
        return `${maxHeightInSlot * 75}px`; // Assuming 60px is the height of one activity
      });

      setMaxHeightPerSlot(newMaxHeightPerSlot);
  }, [classes, events]);

const parseTime = (time, referenceDate) => {
  if (time?.toDate) {
    return time.toDate(); // Handles Firestore Timestamps
  }
  if (typeof time === 'string') {
    const formats = ['h:mm a', 'h:mma', 'H:mm', 'Hmm', 'H:mm a'];
    for (let format of formats) {
      const parsed = parse(time, format, referenceDate);
      if (!isNaN(parsed)) return parsed;
    }
  }
  console.error('Invalid time value:', time);
  return null;
};
// Function to handle click on an activity
const handleActivityClick = (activity) => {
  const startTime = format(activity.startTime, 'h:mm a');
  const endTime = activity.endTime ? format(activity.endTime, 'h:mm a') : 'End Time Unknown';
  const dayFormatted = format(activity.startTime, 'EEEE do');
  let instructorName = 'Unknown Instructor';

  if (activity.type === 'class') {
    const weekday = format(activity.startTime, 'EEEE').toLowerCase();
    const instructorUID = activity.schedule[weekday]?.instructorUID;
    if (instructorUID) {
      instructorName = instructorNames[instructorUID] || instructorName;
    }
  } else if (activity.type === 'event' && activity.instructors) {
    // If 'activity.instructors' is an array of UIDs
    instructorName = activity.instructors.map(uid => instructorNames[uid]).join(", ") || instructorName;
  }

  const message = `${activity.className || activity.title}
Instructor: ${instructorName}
Time: ${startTime} - ${endTime}
Date: ${dayFormatted}`;

  alert(message);
};


return (
    <WeekContainer>
      <WeekGrid>
        <TimeSlotColumn>
          <TopSpacer />
           {hourlyIntervals.map((hour, index) => (
                      <GridCell key={index} style={{ height: maxHeightPerSlot[index] }}>
                {format(hour, 'h:mm a')}

              </GridCell>
            ))}
        </TimeSlotColumn>
        <DayGrid
          className="DayGrid"
         onScroll={handleScroll}
         style={{boxShadow}}>
        {weekDays.map((day, dayIndex) => (
              <DayColumn key={dayIndex}>
                <WeekDayHeader>{format(day, 'EEEE')}
                <br />
                {format(day, 'do')}
                                </WeekDayHeader>
                {hourlyIntervals.map((hour, hourIndex) => {
                  const activities = activitiesMatrix[hourIndex][dayIndex];
                    const maxHeight = maxHeightPerSlot[hourIndex]; // Get the max height for this time slot

                 return (
                   <GridCell key={`${dayIndex}-${hourIndex}`} style={{ height: maxHeight }}>
                     {
                       activities.map((activity, idx) => {
                         if (activity.type === 'class') {
                           // Get the weekday to find the correct schedule
                           const weekday = format(activity.startTime, 'EEEE').toLowerCase();
                           const instructorUID = activity.schedule[weekday]?.instructorUID;
                           const instructor = instructorUID ? instructorNames[instructorUID] : '';

                           return (
                             <ClassItem key={idx} onClick={() => handleActivityClick(activity)}>
                               {activity.className}
                               <InstructorLine>{instructor}</InstructorLine>
                             </ClassItem>
                           );
                         } else {
                           // For non-class types like events, you can handle them as you prefer
                           return (
                             <EventItem key={idx} onClick={() => handleActivityClick(activity)}>
                               {activity.title}
                             </EventItem>
                           );
                         }
                       })
                     }

                   </GridCell>
                 );
                })}
              </DayColumn>
            ))}
            </DayGrid>
          </WeekGrid>
        </WeekContainer>
  );
};

export default WeekView;
