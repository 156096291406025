import React from "react";
import { useState, useEffect } from "react";
import Footer from "../Components/Footer/Footer";
import PresentationMerch from "../Components/Presentations/PresentationMerch";
import ProductCard from "../Components/ProductCard/ProductCard";
import { ProductGrid } from "../Components/ProductCard/ProductCardStyles";

import { db } from "../Firebase"; // Asegúrate de que este import apunte correctamente
import { doc, collection, getDocs, orderBy, query } from "firebase/firestore";

function Merch() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const storeRef = doc(db, "store", "x3aWKMi87yl6nMO0RtLh");
        const productsCollectionRef = collection(storeRef, "products");

        const productsSnapshot = await getDocs(productsCollectionRef);

        const fetchedProducts = productsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setProducts(fetchedProducts);
      } catch (error) {
        console.error("Error al obtener productos:", error);
      }
    };

    fetchProducts();
    
  }, []);

  return (
    <div>
      <PresentationMerch />

      <ProductGrid>
        {products.map((product) => (
          <ProductCard key={product.id} product={product} useGridImageStyle={true}/>
        ))}
      </ProductGrid>

      <Footer />
    </div>
  );
}

export default Merch;
