import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../Firebase";
import { doc, getDoc } from "firebase/firestore";

import {
  PricingDetailsContainer,
  Title,
  Text,
  Highlight,
  Button,
} from "./PricingDetailsStyles";

function PricingDetails() {
  const { collectionName, id } = useParams();
  const [details, setDetails] = useState(null);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const docRef = doc(
          db,
          "LandingConfiguration",
          "x3aWKMi87yl6nMO0RtLh",
          collectionName,
          id
        );
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setDetails(docSnap.data());
        } else {
        }
      } catch (error) {
        console.error("Error fetching details:", error);
      }

      setLoading(false);
    };

    fetchData();
  }, [id, collectionName]);

  if (loading) return <div>Loading...</div>;
  if (!details) return <div>Details not found.</div>;

  // Aquí mostramos los detalles basados en la colección
  return (
    <PricingDetailsContainer>
      <Title>{details.specialDescription || "Membership Details"}</Title>
      <Text>
        Price: <Highlight>{details.price}</Highlight>
      </Text>
      <Text>Description: {details.description}</Text>
      <Text>Duration: {details.duration}</Text>
      {details.collectionName === "AdultAndKidsPricing" && (
        <Text>
          Kids Price: <Highlight>{details.kidsPrice}</Highlight>
        </Text>
      )}
      {details.collectionName === "FamilyMembershipPricing" && (
        <Text>
          Members Price: <Highlight>{details.membersPrice}</Highlight>
        </Text>
      )}
      {/* Más detalles */}
      <Button>Buy Subscription</Button>
    </PricingDetailsContainer>
  );
}

export default PricingDetails;
